
    import Vue from 'vue'
    import dict from '@custom/dict'
    import api from '@api/article/apiMethod'
    const PortalHeader = () => import('../../component/common/PortalHeader.vue')
    const NewsPanel = () => import('../../component/common/NewsPanel.vue')
    const NewsBlock = () => import('../../component/common/NewsBlock.vue')
    const PortalFooter = () => import('../../component/common/PortalFooter.vue')
    import Swiper from "swiper"

    export default Vue.extend({
        name: 'HomePage',
        components: {
            PortalHeader,
            NewsPanel,
            NewsBlock,
            PortalFooter
        },
        data() {
            return {
                dict,
                blockList: [],
                rotateList: [], 
                currentCarousel: 2,
                bannerUrl: ''
            }
        },
        methods: {
            async getExtraOrgList () {
                const self = this as any
                await api.getExtraOrgList({
                    data: {
                        page: 1, 
                        pageSize: 30
                    },
                    success (res: any) {
                        if (res.length > 0) {
                            let obj = res.filter((item: any) => item.id == 5)[0]
                            self.bannerUrl = obj?.description ?? ''
                        }
                    }
                })
            },
            async querySwiperArticle () {
                const self = this as any
                await api.articleQuery({
                    data: {
                        filterConds: self.buildFilterConds({
                            isRotate: '1'
                        })
                    },
                    success (res: any) {
                        if (res.data.length > 0) {
                            self.rotateList = res.data
                        }
                    }
                })
                var swiper = new Swiper('.swiper-container', {
                    slidesPerView: 5,
                    spaceBetween: 10,
                    autoplay: true,
                    delay: '0',
                    centeredSlides: true,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                });
            },
            buildFilterConds (obj: any) {
                const filterConds = []
                for (const key of Object.keys(obj)) {
                    if (obj[key]) {
                        filterConds.push({
                            fieldName: key,
                            fieldValue: obj[key]
                        })
                    }
                }
                return filterConds
            },
            getSideBlockArticle () {
                const self = this as any
                api.articleQuery({
                    data: {
                        filterConds: self.buildFilterConds({
                            moduleId: dict.newsDict.sideBlock
                        }),
                        orderBy: {
                            sort: 'asc'
                        }
                    },
                    success (res: any) {
                        self.blockList = res.data
                    }
                })
            },
            carouselClick(item: any){
                const url = this.$router.resolve({path: '/articledetail', query:{article_id: item.articleId, channel_name: item.moduleTitle}})
                window.open(url.href, '_blank')
            },
            toFrontLine(){
                const url = this.$router.resolve({ path: '/frontLineNew' })
                window.open(url.href, '_blank')
            }
        },
        created () {
            const self = this as any
            self.getSideBlockArticle()
            self.querySwiperArticle()
            self.getExtraOrgList()
        },
    })
